
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ChallengeCard from "@/components/pathway/challenge_card.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "challenge_show",
  props: {
    id: {
      type: String,
      default: null,
    },
    challenge_id: {
      type: String,
      default: null,
    }
  },
  components: {
    ChallengeCard,
  },
  setup(props) {
    onMounted(() => {
      setCurrentPageTitle("Machines List");
      
    });

    const store = useStore();
    const router = useRouter(); 
    console.log(props.id)

    store.commit(Mutations.SET_CURRENT_MACHINE, {});
    store.dispatch(Actions.GET_CHALLENGE, props.challenge_id).then((data) => {});
    store.dispatch(Actions.GET_PATHWAY, props.id);

    const challenge = computed(() => {
      return store.getters.getChallenges;
    });

    const pathway = computed(() => {
      return store.getters.getCurrentPathway;
    });

    return {
      moment,
      challenge,
      store,
      pathway
    };
  },
});
